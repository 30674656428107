import React from "react";
import styles from "./common.module.css";
import IMAGES from "../../Assets";

export default function Landing() {
  return (
    <div className={styles.BackGround}>
      <div className={styles.body}>
        <img
          src={IMAGES.Logo.logoTransparent}
          className={styles.logo}
          alt="../../Assets/Images/Logo.png"
        />
      </div>
    </div>
  );
}
